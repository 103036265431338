import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Intro = memo(function Intro({
  cta,
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper row wrap stretch>
        <LeftSide>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <CTA {...cta} variant="S" />
            </FadeInUp>
          ) : null}
        </LeftSide>
        <RightSide dial={4}>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </RightSide>
      </Wrapper>
      {image ? (
        <Background>
          <LazyLoadImage effect="blur" {...image} />
        </Background>
      ) : null}
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled(FlexBox)`
  padding: 0 5.833vw;
  z-index: 3;

  @media (max-width: 1023px) {
    padding-top: 4.5rem;
  }
`

const LeftSide = styled.div`
  width: 40%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: -3.75rem;
  padding: 6.0625rem 7.5vw 6.0625rem;

  @media (max-width: 1339px) {
    padding-right: 4vw;
    padding-left: 4vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 0;
    padding: 3rem 11.594vw;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  max-width: 360px;
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const CTA = styled(Button)`
  margin-top: 1.875rem;
`

const RightSide = styled(FlexBox)`
  width: 60%;
  padding: 3.75rem 0 3.75rem 9.167vw;
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Description = styled.div`
  max-width: 506px;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

const Background = styled.div`
  height: 58vh;
  margin-top: -3.75rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    height: 35vh;
    margin-top: -5.625rem;
  }
`
